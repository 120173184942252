import React, { useState } from "react";
import { Typography, Box, IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

// Dummy data for testimonials
const testimonials = [
  {
    name: "Prashun Shetty",
    image: require("../../../assets/images/prashun.jpg"),
    text: "I am so pleased with these headshots. I can’t tell you how nice it is to see some photos of myself looking happy and normal! I am absolutely thrilled to have some photos that I can share with my children and use for my website.",
  },
  // {
  //   name: "geeta nayak",
  //   image: require("../../../assets/images/testimonials.png"), 
  //   text: "These headshots exceeded my expectations! I love the natural, relaxed vibe in the photos. It’s refreshing to see such high-quality images that really capture my personality.",
  // },
  // {
  //   name: "Juddy Alexander",
  //   image: require("../../../assets/images/testimonials.png"), 
  //   text: "I'm absolutely thrilled with how the photos turned out! The photographer made me feel so comfortable and the results truly reflect who I am. I can’t wait to share them!",
  // },
];

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0); 

  // Function to handle the "previous" button click
  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  // Function to handle the "next" button click
  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  const { name, image, text } = testimonials[currentIndex]; 

  return (
    <Box
      sx={{
        px: { xs: 2, sm: 3, md: 5, lg: 8 },
        maxWidth: "1600px",
        mx: "auto",
      }}
    >
      <Typography
        variant="h2"
        sx={{
          fontSize: { xs: "50px", sm: "60px", md: "74px" }, 
          fontFamily: "Playfair Display",
          fontWeight: 500,
          mb: 4,
        }}
      >
        Testimonials
      </Typography>
      <Grid container spacing={8} alignItems="center">
        <Grid item md={4} xs={12}>
          <img
            src={image}
            alt="Testimonial"
            style={{
              width: "100%",
              boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
            }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography
            variant="h6"
            sx={{
              fontFamily: "DM Sans",
              fontWeight: 500,
              fontSize: { xs: "24px", md: "32px" },
              mb: 2,
            }}
          >
            {name}
          </Typography>
          <Typography
            sx={{
              mt: 2,
              color: "#747576",
              fontFamily: "DM Sans",
              fontWeight: 400,
              fontSize: { xs: "16px", md: "20px" },
              lineHeight: 1.5,
            }}
          >
            {text}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: 4,
              fontSize: { xs: "16px", md: "20px" },
              color: "#747576",
            }}
          >
            <IconButton
              aria-label="previous"
              size="large"
              onClick={handlePrevious}
            >
              <ArrowBackIcon fontSize="inherit" />
            </IconButton>
            <Typography variant="body1" sx={{ mx: 2 }}>
              {currentIndex + 1} / {testimonials.length}
            </Typography>
            <IconButton aria-label="next" size="large" onClick={handleNext}>
              <ArrowForwardIcon fontSize="inherit" />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Testimonials;
