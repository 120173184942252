import React from "react";
import { Grid, Box } from "@mui/material";
import Tagskills from "../../../assets/images/tagSkills.png";
import AirExpe from "../../../assets/images/airexpe.png";
import CoralFrames from "../../../assets/images/coralFrames.png";
import CuriosStuff from "../../../assets/images/curiostuff.png";
import CrossStorage from "../../../assets/images/crossStorage.png";

const Clients = () => {
  return (
    <Box
      sx={{
        px: { xs: 2, sm: 3, md: 5, lg: 8 },
        maxWidth: "1600px",
        mx: "auto", // Center the container
        margin:"50px 0px"
      }}
    >
      <Grid
        container
        spacing={6}
        sx={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid
          item
          xs={6} 
          sm={4} 
          md={2} 
        >
          <img
            src={Tagskills}
            alt="tagskills"
            style={{ width: "100%", height: "auto" }}
          />
        </Grid>

        <Grid item xs={6} sm={4} md={3}>
          <img
            src={AirExpe}
            alt="airexpe"
            style={{ width: "100%", height: "auto" }}
          />
        </Grid>

        {/* <Grid item xs={6} sm={4} md={3}>
          <img
            src={CoralFrames}
            alt="coralframes"
            style={{ width: "100%", height: "auto" }}
          />
        </Grid> */}

        <Grid item xs={6} sm={4} md={2}>
          <img
            src={CuriosStuff}
            alt="curiostuff"
            style={{ width: "100%", height: "auto" }}
          />
        </Grid>

        <Grid item xs={6} sm={4} md={2}>
          <img
            src={CrossStorage}
            alt="crossstorage"
            style={{ width: "100%", height: "auto" }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Clients;
