import React from "react";
import { Typography, Box, Paper, IconButton, Grid } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import SettingsPhoneOutlinedIcon from "@mui/icons-material/SettingsPhoneOutlined";
import SachinFounder from "../../../src/assets/images/sachin.png";
import HarijithChakrapani from "../../../src/assets/images/HarijithChakrapani.png";
import Prashun from "../../../src/assets/images/prashun.jpg";

import RoyalNithin from "../../../src/assets/images/Royalnithinsequeira.png";

const OurTeam = () => {
  return (
    <section id="team">
      <Box
        sx={{
          px: { xs: 2, sm: 3, md: 5, lg: 8 },
          maxWidth: "1600px",
          mx: "auto",
          py: 1,
        }}
      >
        <Typography
          sx={{
            fontFamily: "Playfair Display",
            fontWeight: 500,
            fontSize: { xs: "48px", md: "74px" },
          }}
        >
          Our Team
        </Typography>

        <Paper sx={{ p: 4, width:"90%"}}>

        <Grid
            container
            spacing={2}
            sx={{ mt: 4, flexDirection: { xs: "column", md: "row" } }}
          >
            <Grid item xs={12} sm={6} md={6}>
              <Typography
                sx={{
                  fontFamily: "Playfair Display",
                  fontWeight: 500,
                  fontSize: { xs: "36px", md: "84px" },
                }}
              >
                Quality of being Grateful and Stylish{" "}
              </Typography>

              <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 500,
                  fontSize: { xs: "24px", md: "24px" },
                  mt: 1,
                }}
              >
                Royal Nithin Sequeira{" "}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 500,
                  fontSize: { xs: "20px", md: "20px" },
                  color: "#747576",
                }}
              >
                Founder & CEO
              </Typography>

              <Box sx={{ display: "flex", gap: 1, my: 2 }}>
                <IconButton
                  component="a"
                  href="https://www.instagram.com/_royal_sequeira_50/"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ color: "#000" }}
                >
                  <InstagramIcon sx={{ fontSize: 30 }} />
                </IconButton>
                <IconButton
                  component="a"
                  href="https://www.linkedin.com/in/royal-sequeira-ab9988225/"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ color: "#000" }}
                >
                  <LinkedInIcon sx={{ fontSize: 30 }} />
                </IconButton>
                <IconButton
                  component="a"
                  href="tel:+123456789"
                  sx={{ color: "#000" }}
                >
                  <SettingsPhoneOutlinedIcon sx={{ fontSize: 30 }} />
                </IconButton>
              </Box>

              <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 700,
                  fontSize: { xs: "20px", md: "20px" },
                }}
              >
                Over the years Royal has developed his expertise and
                photographic style with much love and dedication. Although he is
                the youngest photographer, He had mastered the art of creating
                visually appealing photo series.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <img
                src={RoyalNithin}
                alt="Royal Nithin"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  border: "50px solid #EAECE9",
                }}
              />
            </Grid>
          </Grid>

       
          <Grid
            container
            spacing={2}
            sx={{ mt: 4, flexDirection: { xs: "column", md: "row" } }}
          >
            <Grid item xs={12} sm={6} md={6}>
              <img
                src={HarijithChakrapani}
                alt="Harijith Chakrapani"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  border: "50px solid  #EAECE9",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography
                sx={{
                  fontFamily: "Playfair Display",
                  fontWeight: 500,
                  fontSize: { xs: "36px", md: "74px" },
                }}
              >
                Self Confidence is the Best Fashion
              </Typography>

              <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 500,
                  fontSize: { xs: "16px", md: "16px" },
                  mt: 1,
                }}
              >
                Harijith Chakrapani
              </Typography>
              <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 500,
                  fontSize: { xs: "20px", md: "20px" },
                  color: "#747576",
                }}
              >
                Founder & CEO
              </Typography>

              <Box sx={{ display: "flex", gap: 1, my: 2 }}>
                <IconButton
                  component="a"
                  href="https://www.instagram.com/raven_ad98/"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ color: "#000" }}
                >
                  <InstagramIcon sx={{ fontSize: 30 }} />
                </IconButton>
                <IconButton
                  component="a"
                  href="https://www.linkedin.com/in/harijith-chakrapani-452058269/"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ color: "#000" }}
                >
                  <LinkedInIcon sx={{ fontSize: 30 }} />
                </IconButton>
                <IconButton
                  component="a"
                  href="tel:+123456789"
                  sx={{ color: "#000" }}
                >
                  <SettingsPhoneOutlinedIcon sx={{ fontSize: 30 }} />
                </IconButton>
              </Box>

              <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 700,
                  fontSize: { xs: "20px", md: "20px" },
                }}
              >
                Hari uses the knowledge he has gained as a fashion photographer
                to photograph people who have an eye for style and quality.
                Through his years of experience, he knows how to get the best
                out of the person in front of his lens, regardless of their
                experience in front of the camera.
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            sx={{
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",margin:"auto"
            }}
          >
            <Grid item xs={12} sm={6} md={3}>
              <Typography
                sx={{
                  fontFamily: "Playfair Display",
                  fontWeight: 500,
                  fontSize: { xs: "36px", md: "74px" },
                  position: "relative",
                }}
              >
                I Really Love to{" "}
                <span style={{ position: "relative", whiteSpace: "nowrap" }}>
                  Talk with <span style={{ color: "#FFFFFF" }}>People</span>
                </span>
              </Typography>

              <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 500,
                  fontSize: { xs: "24px", md: "32px" },
                  mt: 1,
                }}
              >
                Sachin Kocharekar
              </Typography>
              <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 500,
                  fontSize: { xs: "20px", md: "16px" },
                  color: "#747576",
                }}
              >
                Founder & CTO
              </Typography>

              <Box sx={{ display: "flex", gap: 1, my: 2 }}>
                <IconButton
                  component="a"
                  href="https://www.instagram.com/iamsachinkar/"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ color: "#000" }}
                >
                  <InstagramIcon sx={{ fontSize: 28 }} />
                </IconButton>
                <IconButton
                  component="a"
                  href="https://www.linkedin.com/in/sachin-kocharekar-40799142/"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ color: "#000" }}
                >
                  <LinkedInIcon sx={{ fontSize: 28 }} />
                </IconButton>
                <IconButton
                  component="a"
                  href="tel:+123456789"
                  sx={{ color: "#000" }}
                >
                  <SettingsPhoneOutlinedIcon sx={{ fontSize: 28 }} />
                </IconButton>
              </Box>

              {/* <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 700,
                  fontSize: { xs: "20px", md: "16px" },
                }}
              >
                While creating photography we can get inspired by anything: From
                films to exhibitions, from good music to special fabrics, but
                also by stories and beautiful memories.
              </Typography> */}
            </Grid>

            <Grid item xs={12} sm={6} md={9}>
              <img
                src={SachinFounder}
                alt="Sachin the great founder"
                style={{ width: "100%",maxWidth:"800px", height: "auto" }}
              />
              <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 700,
                  fontSize: { xs: "20px", md: "20px" },
                }}
              >
                Sachin, the Founder of Invayas Technologies, is a Senior Manager at a leading Indian MNC with a strong passion for coding. Despite his leadership role, he continues to write code daily, staying hands-on with the latest technologies. He is deeply inspired by the fashion industry, drawing creativity from the people, collections, and brands he collaborates with. With a unique blend of technology and fashion, Sachin aims to build innovative digital solutions that drive transformation..
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            sx={{ mt: 4, flexDirection: { xs: "column", md: "row" } }}
          >
            <Grid item xs={12} sm={6} md={6}>
              <img
                src={Prashun}
                alt="Prashun Shetty"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  border: "50px solid  #EAECE9",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography
                sx={{
                  fontFamily: "Playfair Display",
                  fontWeight: 500,
                  fontSize: { xs: "36px", md: "74px" },
                }}
              >
                Great style begins with great self-belief.
              </Typography>

              <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 500,
                  fontSize: { xs: "16px", md: "16px" },
                  mt: 1,
                }}
              >
                Prashun Shetty
              </Typography>
              <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 500,
                  fontSize: { xs: "20px", md: "20px" },
                  color: "#747576",
                }}
              >
                Cofounder & CBO
              </Typography>

              <Box sx={{ display: "flex", gap: 1, my: 2 }}>
                <IconButton
                  component="a"
                  href="https://www.instagram.com/prashunshetty_/"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ color: "#000" }}
                >
                  <InstagramIcon sx={{ fontSize: 30 }} />
                </IconButton>
                <IconButton
                  component="a"
                  href="https://www.linkedin.com/in/prashun-shetty-41903a39/"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ color: "#000" }}
                >
                  <LinkedInIcon sx={{ fontSize: 30 }} />
                </IconButton>
                <IconButton
                  component="a"
                  href="tel:+123456789"
                  sx={{ color: "#000" }}
                >
                  <SettingsPhoneOutlinedIcon sx={{ fontSize: 30 }} />
                </IconButton>
              </Box>

             
            </Grid>
            <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 700,
                  fontSize: { xs: "20px", md: "20px" },
                }}
              >
                Prashun Shetty, Founder & CEO of TagSkills and Founder of Invayas Technologies, has held senior roles in multiple MNCs, bringing 
                extensive expertise in technology and leadership. Passionate about teaching, he has inspired thousands through TagSkills, helping 
                them achieve their career dreams. Beyond tech, he is deeply influenced by the fashion industry, 
                finding creativity in the people, collections, and brands he collaborates with. Through CoralFrames, he aims to merge fashion and technology,
                 creating innovative digital experiences that empower brands and creators. His vision is to shape the future of fashion 
                 with cutting-edge solutions and creative storytelling..
              </Typography>
          </Grid>
        </Paper>
      </Box>
    </section>
  );
};

export default OurTeam;
