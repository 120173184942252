import React from "react";
import { Box, Grid, Typography, IconButton } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import SettingsPhoneOutlinedIcon from "@mui/icons-material/SettingsPhoneOutlined";
import FooterLogo from "../../../assets/images/footerLogo.png";

const Footer = () => {
  const navigate = useNavigate();

  // Function to scroll to a section on the Home page
  const handleScrollTo = (id) => {
    navigate("/"); // Navigate to Home page first
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // Adjust delay if needed for smooth scroll
  };

  return (
    <Box
      sx={{
        px: { xs: 2, sm: 3, md: 5, lg: 8 },
        maxWidth: "1600px",
        mx: "auto",
        py: 4,
        bgcolor: "#EAECE9",
      }}
    >
      <Grid
        container
        spacing={4}
        alignItems="center"
        justifyContent="space-between"
      >
        {/* Left Section: Text and Social Media Icons */}
        <Grid item xs={12} md={5}>
          <Typography
            variant="h3"
            sx={{
              fontFamily: "DM Sans",
              fontWeight: 500,
              fontSize: { xs: "28px", sm: "32px", md: "32px" },
              color: "#333",
              lineHeight: 1.2,
              mb: 2,
              width:"80%"
            }}
          >
            Follow for photoshoots and marketing insights.
          </Typography>
          {/* Social Media Icons */}
          <Box sx={{ display: "flex", gap: 1, my: 2 }}>
            <IconButton
              component="a"
              href="https://www.instagram.com/coralframesofficial/"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: "#000" }}
            >
              <InstagramIcon sx={{ fontSize: { xs: 25, sm: 30 } }} />
            </IconButton>
            <IconButton
              component="a"
              href="https://linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: "#000" }}
            >
              <LinkedInIcon sx={{ fontSize: { xs: 25, sm: 30 } }} />
            </IconButton>
            <IconButton
              component="a"
              href="tel:+919019307288"
              sx={{ color: "#000" }}
            >
              <SettingsPhoneOutlinedIcon
                sx={{ fontSize: { xs: 25, sm: 30 } }}
              />
                <Typography
            sx={{
              fontFamily: "DM Sans",
              fontWeight: 400,
              fontSize: "16px",
            }}
          > +91 9019307288</Typography>
            </IconButton>
          </Box>
          <Typography sx={{ fontSize: "16px", color: "#747576" }}>
            &copy; 2024. All rights reserved.
          </Typography>
        </Grid>

        {/* Right Section: Logo and Links */}
        <Grid item xs={12} md={7}>
          <Box display="flex" justifyContent="flex-end">
            <Box
              component="img"
              src={FooterLogo}
              alt="coralframe logo"
              sx={{
                backgroundColor:"#fff",
                maxWidth: { xs: "150px", md: "200px" },
                padding:"20px",
             
              }}
            />
          </Box>
          <Box display="flex" alignItems="center" gap={{ xs: 2, md: 4 }}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems={{ xs: "center", md: "flex-end" }}
              gap={2}
              textAlign={{ xs: "center", md: "right" }}
            >
              {/* First Row of Links */}
              <Box
                display="flex"
                gap={{ xs: 2, sm: 4 }}
                flexWrap="wrap"
                sx={{
                  textDecoration: "none",
                  fontSize: "16px",
                  color: "#747576",
                  fontWeight: 700,
                  fontFamily: "DM Sans",
                }}
              >
                <NavLink
                  to="/"
                  onClick={() => handleScrollTo("home")}
                  style={{ color: "#747576", textDecoration: "none" }}
                >
                  Home
                </NavLink>
                <NavLink
                  to="/"
                  onClick={() => handleScrollTo("about")}
                  style={{ color: "#747576", textDecoration: "none" }}
                >
                  About Us
                </NavLink>
                <NavLink
                  to="/packages"
                  style={{ color: "#747576", textDecoration: "none" }}
                >
                  Packages
                </NavLink>
                <NavLink
                  to="/"
                  onClick={() => handleScrollTo("team")}
                  style={{ color: "#747576", textDecoration: "none" }}
                >
                  Our Team
                </NavLink>
              </Box>

              {/* Second Row of Links */}
              <Box
                display="flex"
                gap={{ xs: 2, sm: 4 }}
                flexWrap="wrap"
                sx={{
                  textDecoration: "none",
                  fontSize: "16px",
                  color: "#747576",
                  fontWeight: 700,
                  fontFamily: "DM Sans",
                }}
              >
                <NavLink
                  to="/privacy-policy"
                  style={{ color: "#747576", textDecoration: "none" }}
                >
                  Privacy Policy
                </NavLink>
                <NavLink
                  to="/brand-policy"
                  style={{ color: "#747576", textDecoration: "none" }}
                >
                  Brand Policy
                </NavLink>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
